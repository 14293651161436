import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import { useEffect, useState } from 'react'
import axios from 'axios'

export function MenuInner() {
  const [settingdata, setSettingdata] = useState<any[]>([]);
  const [loadingsetting, setLoadingsetting] = useState(true);
  const {currentUser} = useAuth()
  const intl = useIntl()

  const API_URL = process.env.REACT_APP_API_URL;
  const GET_ALL_SETTINGS = `${API_URL}/GetAllSettings`

  function fetchSettings(){
    //setLoading(true)
    axios({
      method: "get",
      url: GET_ALL_SETTINGS,
    }).then(function (response) {
      //console.log(response.data);
      setSettingdata(response.data);
      setLoadingsetting(false)
    });
  }
  useEffect(() => {
    fetchSettings()
  }, []);

  return (
    <>
      <MenuItem title='Home' to={toAbsoluteUrl('/dashboard')} />
      {/* <MenuItem title='Be a Volunteer' to='/pages/wizards/BeAVolunteer' /> */}
      {(!loadingsetting && String(settingdata[8].setVal).includes("0")) &&
        <MenuItem title='Volunteer' to={toAbsoluteUrl('/BeAVolunteer')} />
      }
      {(!loadingsetting && String(settingdata[9].setVal).includes("0")) &&
        <MenuInnerWithSub title='Programmes' to={toAbsoluteUrl('/Programme')} menuPlacement='bottom-start' menuTrigger='click'>
          {/* PAGES */}
          <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/kindergarten')} title='Kindergarten'/>
          <MenuInnerWithSub
            title='Primary'
            to='/Programme/primary'
            icon='profile-user'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
          <MenuItem to={toAbsoluteUrl('/Programme/primary-1')} title='Primary 1' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/primary-2')} title='Primary 2' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/primary-3')} title='Primary 3' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/primary-4')} title='Primary 4' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/primary-5')} title='Primary 5' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/primary-6')} title='Primary 6' hasBullet={true}/>
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Secondary'
            to='/Programme/secondary'
            icon='profile-user'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
          <MenuItem to={toAbsoluteUrl('/Programme/secondary-1')} title='Secondary 1' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/secondary-2')} title='Secondary 2' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/secondary-3')} title='Secondary 3' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/secondary-4')} title='Secondary 4' hasBullet={true}/>
          <MenuItem to={toAbsoluteUrl('/Programme/secondary-5')} title='Secondary 5' hasBullet={true}/>
          </MenuInnerWithSub>
          <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/ite')} title='ITE' />
        {/*  <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/poly')} title='Poly' />
          <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/jc')} title='JC' />
          <MenuItem icon='profile-user' to={toAbsoluteUrl('/Programme/private')} title='Private' /> */}
        </MenuInnerWithSub>
      }
      {(!loadingsetting && String(settingdata[7].setVal).includes("0")) &&
      <MenuItem title='Partners' to='/Partners' />
      }
      {(!loadingsetting && String(settingdata[5].setVal).includes("0")) &&
      <MenuItem title='Shops' to='/Shops' />
      }
      {(currentUser && (!loadingsetting && String(settingdata[10].setVal).includes("0"))) &&
      <MenuItem title='QR Code Scanner' to='/QrScanner' />
      }
    </>
  )
}
