import React, {FC,useState,useEffect, useMemo, useRef} from 'react'
import ImageSlider from './ImageSlider';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';
import { SectionHeader } from './SectionHeader';
import {useAuth} from '../../../app/modules/auth'
import {CheckPermissions,GetAllSettings,SearchContent,GetAllImageSlider,UpdateContent,GetRandomProgrammes,GetRandomTestimonial} from '../../modules/auth/core/_requests';
import LoadingAnimation from '../../LoadingAnimation';
import axios from 'axios';

const DashboardMain = () => {
  const {currentUser} = useAuth()
  const [settingdata, setSettingdata] = useState<any[]>([]);
  const [sliderdata, setSliderdata] = useState<any[]>([]);
  const [section2data, setSection2data] = useState<any[]>([]);
  const [section3data, setSection3data] = useState<any[]>([]);
  const [section4data, setSection4data] = useState<any[]>([]);
  const [section5data, setSection5data] = useState<any[]>([]);
  const [randomprogramme, setRandomProgramme] = useState<any[]>([]);
  const [randomtestimoni, setRandomTestimoni] = useState<any[]>([]);
  const [loadingsetting, setLoadingsetting] = useState(true);
  const [loadingslider, setLoadingslider] = useState(false);
  const [loadingsection2, setLoadingsection2] = useState(false);
  const [loadingsection3, setLoadingsection3] = useState(false);
  const [loadingsection4, setLoadingsection4] = useState(false);
  const [loadingsection5, setLoadingsection5] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;
  const GET_ALL_SETTINGS = `${API_URL}/GetAllSettings`
    // ============================ start check permission ============================
    const [loadingPerms, setLoadingPerms] = useState(false);
    const refEdit = useRef(false);
    const refSetting = useRef<any[]>([]);

    const fetchPerms = async () => {
        setLoadingPerms(true)
        const {data: resFunctionEdit} = await CheckPermissions(Number(currentUser?.usrRoleId),"contents","Update")
   
        refEdit.current = resFunctionEdit.statusFunction;
   
        setLoadingPerms(false)
    };
    // ============================ end check permission ============================
  const fetchContents = async () => {
    setLoadingslider(true);
    const {data: resx} = await GetAllImageSlider()
    setSliderdata(resx);
    setLoadingslider(false);
  };
  const fetchContents2 = async () => {
    setLoadingsection2(true);
    const {data: res} = await SearchContent("section2")
    setSection2data(res);
    setLoadingsection2(false);
  };
  const fetchContents3 = async () => {
    setLoadingsection3(true);
    const {data: res} = await SearchContent("section3")
    setSection3data(res);
    const {data: resp} = await GetRandomProgrammes()
    setRandomProgramme(resp);
    setLoadingsection3(false);
  };
  const fetchContents4 = async () => {
    setLoadingsection4(true);
    const {data: res} = await SearchContent("section4")
    setSection4data(res);
    const {data: rest} = await GetRandomTestimonial()
    setRandomTestimoni(rest);
    setLoadingsection4(false);
  };
  const fetchContents5 = async () => {
    setLoadingsection5(true);
    const {data: res} = await SearchContent("section5")
    setSection5data(res);
    setLoadingsection5(false);
  };
  const fetchSettings = async () => {
    setLoadingsetting(true)
    const {data: ress} = await GetAllSettings()
    refSetting.current = ress;
    setSettingdata(ress)
    console.log(ress)
    setLoadingsetting(false)
    //setSettingdata(ress);
  };
  useEffect(() => {
    fetchSettings();
/*     axios({
      method: "get",
      url: GET_ALL_SETTINGS,
    }).then(function (response) {
      alert("gandhi");
      refSetting.current = response.data;
    }); */

    fetchPerms();
    fetchContents();
    fetchContents2();
    fetchContents3();
    fetchContents4();
    fetchContents5();
  }, []);
  return (
    <> 
        <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-12'>
            <SectionHeader className='card-xl-stretch mb-5 mb-xl-8' />
          </div>
        </div>
        {(!loadingsetting && String(settingdata[4].setVal).includes("0")) &&
          <>
          {loadingslider ? <LoadingAnimation/> : <ImageSlider sliderdata={sliderdata}/>}
          </>
        }
        {(!loadingsetting && String(settingdata[0].setVal).includes("0")) &&
        <>
        {loadingsection2 ? <LoadingAnimation/> : <SectionTwo section2datax={section2data} hasContentEdit={refEdit.current} />}
        </>
        }
        {(!loadingsetting && String(settingdata[1].setVal).includes("0")) &&
        <>
        {loadingsection3 ? <LoadingAnimation/> : <SectionThree section3datax={section3data} randomprogramme={randomprogramme} hasContentEdit={refEdit.current}/>}
        </>
        }
        {(!loadingsetting && String(settingdata[2].setVal).includes("0")) &&
        <>
        {loadingsection4 ? <LoadingAnimation/> : <SectionFour section4datax={section4data} randomtestimoni={randomtestimoni} hasContentEdit={refEdit.current}/>}
        </>
        }
        {(!loadingsetting && String(settingdata[3].setVal).includes("0")) &&
        <>
        {loadingsection5 ? <LoadingAnimation/> : <SectionFive section5datax={section5data} hasContentEdit={refEdit.current}/>}
        </>
        }
    </>
  )
}
  export default DashboardMain;